import { useLocalStorage } from "./useLocalStorage";

interface AppState {
  hasName: boolean;
  presentVRFinished: boolean;
  presentDNAFinished: boolean;
  presentDinnerFinished: boolean;
}

const initialState: AppState = {
  hasName: false,
  presentDNAFinished: false,
  presentDinnerFinished: false,
  presentVRFinished: false,
};

export const useAppState = () => {
  return useLocalStorage("appState", initialState, 23);
};
