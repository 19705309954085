import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Text,
  Alert,
  AlertDescription,
} from "@chakra-ui/core";
import React, { lazy, Suspense, useCallback, useState } from "react";
import { useAppState } from "../hooks/useAppState";
import { PresentPage } from "./PresentPage";
import { motion } from "framer-motion";

const Runner = lazy(() => import("../lib/Runner"));

const minScore = 823;

export const Present2 = () => {
  const [state, setState] = useAppState();
  const [canOpen, setCanOpen] = useState(state.presentDNAFinished);

  const setWin = useCallback(() => {
    setState((state) => ({
      ...state,
      presentDNAFinished: true,
    }));
  }, [setState]);

  const handleGameOver = useCallback(
    (score: number) => {
      if (score >= minScore) {
        setCanOpen(true);
      }
    },
    [setCanOpen]
  );

  if (state.presentDNAFinished) {
    return (
      <PresentPage>
        <Flex p={4} pt={0} minH="full" direction="column" align="center">
          <Heading textAlign="center" fontWeight="normal" mb={5}>
            Svůj druhý dárek...
          </Heading>
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 2 }}
          >
            <Heading textAlign="center" fontWeight="normal" mb={15}>
              Najdeš schovaný doma{" "}
              <span role="img" aria-label="game">
                😉
              </span>
            </Heading>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 5 }}
          >
            <Box my={10} textAlign="center" w="full">
              <Alert status="success" variant="solid">
                <Text fontSize={32} mr={5}>
                  <span role="img" aria-label="computer">
                    💡
                  </span>
                </Text>
                <AlertDescription maxWidth="sm">
                  Nápověda: Krabice, do které ses nikdy nedívala
                </AlertDescription>
              </Alert>

              <Text fontSize={30} mt={10}>
                <span role="img" aria-label="game">
                  🧬
                </span>
              </Text>
            </Box>
          </motion.div>
        </Flex>
      </PresentPage>
    );
  }

  return (
    <PresentPage>
      <Flex p={4} pt={0} direction="column" align="center">
        <Heading mb={4} fontWeight="normal" textAlign="center">
          Pro odhalení dárku získej alespoň {minScore} bodů
        </Heading>

        <Suspense fallback={<Spinner mt={"100px"} mb={"200px"} />}>
          <Box mb={4} w={"full"}>
            <Runner key="runner" onGameOver={handleGameOver} />
          </Box>
        </Suspense>
        <Text mb={5}>Začni kliknutím na dinosaura</Text>
        {canOpen && (
          <Button size="lg" variantColor="green" onClick={setWin}>
            Odhal dárek
          </Button>
        )}
      </Flex>
    </PresentPage>
  );
};
