import { useState } from "react";

interface LSRecord<T> {
  version: number;
  value: T;
}

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  version: number = 1
): [T, React.Dispatch<React.SetStateAction<T>>] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue

      if (!item) {
        return initialValue;
      }

      const parsed: LSRecord<T> = JSON.parse(item);

      if (!parsed || parsed.version !== version) {
        return initialValue;
      }
      return parsed.value;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: React.SetStateAction<T>) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(
        key,
        JSON.stringify({ value: valueToStore, version } as LSRecord<T>)
      );
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue];
}
