import { theme as defaultTheme, DefaultTheme } from "@chakra-ui/core";

// Let's say you want to add custom colors
export const theme: DefaultTheme = {
  ...defaultTheme,
  fonts: {
    ...defaultTheme.fonts,
    body: "helvetica, sans-serif",
    heading: "helvetica, sans-serif",
  },
  fontWeights: {
    ...defaultTheme.fontWeights,
    normal: 200,
    medium: 300,
    bold: 400,
    light: 100,
  },
};
