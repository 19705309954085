import logo from "./logo.svg";
import { CSSReset, ThemeProvider, Box, DefaultTheme } from "@chakra-ui/core";
import React from "react";
import { theme } from "./theme";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Login } from "./components/Login";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { Main } from "./components/Main";
import { Present1 } from "./components/Present1";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";
import { Present2 } from "./components/Present2";
import { Present3 } from "./components/Present3";
import { AnimatePresence } from "framer-motion";
import { Thirty } from "./components/Thirty";

const Body = styled(Box)`
  // background: linear-gradient(20deg, #2bc0e4, #eaecc6 60%);
  background: linear-gradient(20deg, #00b4db, #0083b0 80%);
  color: ${(p) => (p.theme as DefaultTheme).colors.gray[50]};
`;

export function App() {
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Global
        styles={css`
          body {
            background-color: #0083b0;
          }
        `}
      />

      <BrowserRouter>
        <Body
          position="fixed"
          top={0}
          bottom={0}
          left={0}
          right={0}
          overflow="auto"
        >
          <AnimatePresence>
            <Switch>
              <Route path="/login">
                <Login />
              </Route>
              <ProtectedRoute path="/darek1">
                <Present1 />
              </ProtectedRoute>
              <ProtectedRoute path="/darek3">
                <Present3 />
              </ProtectedRoute>
              <ProtectedRoute path="/darek2">
                <Present2 />
              </ProtectedRoute>
              <Route path="/30">
                <Thirty />
              </Route>
              {/* <ProtectedRoute path="/">
                <Main />
              </ProtectedRoute> */}
              <Route path="/">
                <Redirect
                  push={true}
                  to={{
                    pathname: "https://qhmoxyv07ip.typeform.com/to/L7VK7lF5",
                  }}
                />
              </Route>
            </Switch>
          </AnimatePresence>
        </Body>
      </BrowserRouter>
    </ThemeProvider>
  );
}
