import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useAppState } from "../hooks/useAppState";

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const ProtectedRoute: FC<RouteProps> = ({ children, ...rest }) => {
  const [state] = useAppState();
  return (
    <Route
      {...rest}
      render={() =>
        state.hasName ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
};
