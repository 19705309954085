import React, { FC } from "react";
import { Box, Icon, Link } from "@chakra-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";

const variants = {
  enter: {
    zIndex: 0,
    scale: 0.8,
    opacity: 0,
  },
  animate: {
    zIndex: 1,
    scale: 1,
    opacity: 1,
  },
  exit: {
    scale: 1.2,
    zIndex: 2,
    opacity: 0,
  },
};

export const PresentPage: FC = ({ children }) => {
  return (
    <motion.div
      key="main"
      variants={variants}
      initial="enter"
      animate="animate"
      exit="exit"
      transition={{
        opacity: { duration: 0.5 },
      }}
    >
      <Box top={0} w="full" p={4} fontSize="lg">
        <RouterLink component={Link} to="/">
          {<Icon name="arrow-back" />} Zpět na hlavní stránku
        </RouterLink>
      </Box>
      {children}
    </motion.div>
  );
};
