import { Box, Flex, Heading, Image, Stack, Text } from "@chakra-ui/core";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import terinka from "../assets/terinka.jpeg";
import confetti from "../lib/confetti";

const reasons = [
  "Pro tvůj krásný úsměv ",
  "Protože mi dáváš najevo, jak moc pro tebe znamenám",
  "Pro tvůj krásný zadek ",
  "Pro tvé krásné oči ",
  "Protože díky tobě má můj život mnohem větší smysl ",
  "Pro tvoje krevetový těstoviny a všechny další dobroty, co mi vaříš",
  "Proto jak krásně tančíš 💃",
  "Pro tvoje božská prsa",
  "Pro tvůj smysl pro humor ",
  "Proto jaký máš hezký vztah s rodinou ",
  "Protože mi ráda pereš a žehlíš",
  "Protože jsi bohyně sexu",
  "Protože jsi chytrá",
  "Protože se díky tobě cítím jako lepší člověk, než jaký jsem",
  "Protože si do mého života přinesla Brooklyn 99 a The Office",
  "Protože tak krásně voníš",
  "Proto jaký jsi mazel",
  "Pro naše společné návštěvy restaurací",
  "Proto jaká jsi bojovnice, když ti zrovna zdraví nepřeje ",
  "Proto jak si na mě hodná, když mám kocovinu",
  "Pro naše společné dovolené",
  "Protože jsme měli tu nejlepší svatbu na světě",
  "Protože jsem s tebou šťastný ",
  "Protože se přátelíš s mými přáteli",
  "Protože když se náhodou někdy hádáme, vždycky se zase rychle usmíříme",
  "Proto jak jsi roztomilá, když jsi zvědavá ",
  "Pro tvůj štíhlý pas",
  "Protože pořád posloucháš všechna moje 'moudra' a děláš, jak jsem chytrej :D",
  "Proto jak moc ti záleží na našem vztahu ",
  "Protože jsi to nejlepší, co jsem našel na internetu",
];

const birthday = new Date(2022, 7, 23);
const meetingTime = new Date(2022, 7, 23, 18);
const start = new Date(2022, 7, 23, 9);

const period = (meetingTime.valueOf() - start.valueOf()) / 30;

const variants = {
  enter: {
    scale: 1.2,
    zIndex: 2,
    opacity: 0,
  },
  animate: {
    zIndex: 1,
    scale: 1,
    opacity: 1,
  },
  exit: {
    zIndex: 0,
    scale: 0.8,
    opacity: 0,
  },
};

const useConfetti = () => {
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    confetti.start?.();
    return () => {
      // eslint-disable-next-line no-unused-expressions
      confetti.stop?.();
    };
  }, []);
};

export const Thirty = () => {
  useConfetti();
  return (
    <motion.div
      key="main"
      variants={variants}
      initial="enter"
      animate="animate"
      exit="exit"
      transition={{
        opacity: { duration: 0.5 },
      }}
    >
      <Flex
        w="full"
        // h="full"
        direction="column"
        // justify="space-between"
        align="center"
        py={10}
        px={5}
      >
        <Box size={[40, 64]} mb={[5, 10]}>
          <Image
            rounded="full"
            src={terinka}
            key="terinkaNaObrazku"
            shadow="xl"
            alt="terinka pije víno"
          />
        </Box>
        <Heading fontWeight="light" as="h1" mb={3}>
          Drahá Terinko{" "}
          <span role="img" aria-label="celebrate">
            🥳
          </span>
        </Heading>
        <Heading textAlign="center" fontSize={22} mb={2}>
          Tobě je dneska 30!!!
        </Heading>
        <Text fontSize="lg" textAlign="justify">
          Doufám, že se raduješ a nesmutníš. Život teprve začíná! Náš společný
          život!
        </Text>
        <Text fontSize="lg" textAlign="justify">
          Hned ze začátku se omlouvám, nemám pro tebe připravené žádné hry ani
          soutěže. Veřím ale, že se ti večer bude líbit 😘
        </Text>
        <Text fontSize="lg" textAlign="justify">
          Potkáme se dnes v 18:00 na Masarykově Nádraží. Vem si na sebe něco
          pěkného 😏
        </Text>

        <Text pt="20" fontSize="lg" textAlign="justify">
          Aby se ti lépe čekalo, připravil jsem ti tu 30 kartiček, které se ti
          během dne budou postupně odkrývat.
        </Text>
        {/* <Text pt="10" pb="10" fontSize="xl" textAlign="center">
          30 důvodů, proč tě miluju
        </Text> */}
        <Heading fontWeight="light" as="h2" my={10}>
          30 důvodů, proč tě miluju ❤️
        </Heading>
        <Reasons />
      </Flex>
    </motion.div>
  );
};

function Card(props) {
  return (
    <Box
      {...props}
      p="10"
      py="5"
      backgroundColor="white"
      borderWidth="1px"
      borderColor="gray"
      borderRadius="lg"
      boxShadow="2xl"
    >
      <Text fontSize="2xl" color="black" textAlign="center">
        ❤️ {props.children}
      </Text>
    </Box>
  );
}

function HiddenCard(props) {
  return (
    <Box
      {...props}
      p="10"
      py="5"
      backgroundColor="white"
      borderWidth="1px"
      borderColor="gray"
      borderRadius="lg"
      boxShadow="2xl"
    >
      <Text fontSize="2xl">❓</Text>
    </Box>
  );
}

function calculateIdx() {
  const now = new Date();

  const diff = now.valueOf() - start.valueOf();
  const minutes = Math.floor(diff / 1000 / 60);
  const currIdx = Math.floor(minutes / 18);
  console.log({ diff, minutes, currIdx });

  return currIdx;
}

function Reasons() {
  const now = new Date();
  const [idx, setIdx] = useState(() => calculateIdx());

  useEffect(() => {
    const stop = setInterval(() => {
      setIdx(calculateIdx());
    }, 60000);
    return () => stop();
  }, []);

  if (now.valueOf() < start.valueOf()) {
    return null;
  }

  return (
    <Stack isInline flexWrap="wrap" spacing={4}>
      {reasons.map((reason, i) =>
        i < idx ? (
          <Card mb={4} key={reason}>
            {reason}
          </Card>
        ) : (
          <HiddenCard mb={4} key={reason} />
        )
      )}
    </Stack>
  );
}
