import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Spinner,
  Text,
} from "@chakra-ui/core";
import React, { lazy, Suspense, useCallback, useRef, useState } from "react";
import puzzle from "../assets/puzzle.jpeg";
import vr from "../assets/vr.png";
import { useAppState } from "../hooks/useAppState";
import { PresentPage } from "./PresentPage";

const SlidingPuzzle = lazy(() => import("../lib/sliding"));

export const Present1 = () => {
  const [state, setState] = useAppState();
  const [canOpen, setCanOpen] = useState(state.presentVRFinished);
  const sliderRef = useRef(null);

  const setWin = useCallback(() => {
    setState((state) => ({
      ...state,
      presentVRFinished: true,
    }));
  }, [setState]);

  const handleSuccess = useCallback(() => {
    setCanOpen(true);
  }, [setCanOpen]);

  const handleLoad = useCallback(() => {
    (sliderRef.current as any)?.shuffle();
  }, []);

  if (state.presentVRFinished) {
    return (
      <PresentPage>
        <Flex p={4} pt={0} direction="column" align="center">
          <Heading textAlign="center" fontWeight="normal" mb={5}>
            Tvým prvním dárkem je...
          </Heading>
          <Box maxW="400px" mb={5}>
            <Image
              src={vr}
              shadow="lg"
              rounded="lg"
              border="1px"
              borderRadius="md"
              borderColor="gray.200"
            />
          </Box>
          <Heading textAlign="center" fontWeight="normal" mb={5}>
            Hodina ve virtuální realitě!
          </Heading>
          <Text fontSize="lg" textAlign="center">
            Kde? U nás ve sklepě.{" "}
            <Link href="https://www.virtualnirealita.cz/" isExternal>
              virtualnirealita.cz
              <Icon name="external-link" mx="2px" />
            </Link>
          </Text>
          <Text fontSize="lg" mb={5}>
            Kdy? Kdy budeš chtít.
          </Text>
          <Text fontSize="lg">
            <span role="img" aria-label="game">
              🕹
            </span>
          </Text>
        </Flex>
      </PresentPage>
    );
  }

  return (
    <PresentPage>
      <Flex p={4} pt={0} direction="column" align="center">
        <Heading mb={4} fontWeight="normal" textAlign="center">
          Pro odhalení dárku vyřeš puzzle
        </Heading>
        <Box mb={4}>
          <Suspense fallback={<Spinner />}>
            <SlidingPuzzle
              ref={sliderRef}
              src={puzzle}
              onLoad={handleLoad}
              onSolve={handleSuccess}
              rows={3}
              cols={3}
              maxWidth={350}
              maxHeight={350}
              minWidth={300}
              minHeight={300}
              key={"puzzle"}
            />
          </Suspense>
        </Box>
        {canOpen && (
          <Button size="lg" variantColor="green" onClick={setWin}>
            Odhal dárek
          </Button>
        )}
      </Flex>
    </PresentPage>
  );
};
