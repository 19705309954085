import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Image,
  Input,
} from "@chakra-ui/core";
import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import babis from "../assets/babis.jpg";
import { useAppState } from "../hooks/useAppState";

const allowedNames = ["tereza", "terka", "terinka", "terezka", "tery"];
const allowedDate = "1992-08-23";

export const Login = () => {
  const [appState, setAppState] = useAppState();
  const [showBabis, setShowBabis] = useState(false);
  const [name, setName] = useState("");
  const [age, setAge] = useState("");

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      const correctName = allowedNames.some((allowed) =>
        name.toLowerCase().includes(allowed)
      );
      const correctAge = age === allowedDate;

      if (correctName && correctAge) {
        setAppState((state) => {
          return {
            ...state,
            hasName: true,
          };
        });
      } else {
        setShowBabis(true);
      }
    },
    [age, name, setAppState]
  );

  if (appState.hasName) {
    return <Redirect to="/" />;
  }

  return (
    <Flex
      w="full"
      h="full"
      direction="column"
      justify="center"
      align="center"
      py={10}
      px={5}
    >
      {showBabis ? (
        <>
          <Box mb={4}>
            <Image src={babis} shadow="lg" rounded="lg" />
          </Box>
          <Heading textAlign="center" mb={5}>
            Tak to sorry jako
          </Heading>
          <Button variantColor="teal" onClick={() => setShowBabis(false)}>
            Zkus to znovu
          </Button>
        </>
      ) : (
        <>
          <Heading
            // fontFamily="heading"
            fontWeight="light"
            // textAlign="left"
            mb={4}
          >
            Ahoj
          </Heading>
          <form onSubmit={handleSubmit}>
            <FormLabel htmlFor="name" fontWeight="bold">
              Jak se jmenuješ?
            </FormLabel>
            <Input
              type="text"
              id="name"
              mb={2}
              value={name}
              color={"gray.700"}
              onChange={(e: any) => setName(e.target.value)}
            />
            <FormLabel htmlFor="age" fontWeight="bold">
              A kdy ses narodil(a)?
            </FormLabel>
            <Input
              type="date"
              id="age"
              mb={2}
              value={age}
              color={"gray.700"}
              onChange={(e: any) => setAge(e.target.value)}
            />

            <Button mt={4} variantColor="teal" type="submit">
              Vstoupit
            </Button>
          </form>
        </>
      )}
    </Flex>
  );
};
