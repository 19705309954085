import { Box, Flex, Heading, Image, Stack, Text } from "@chakra-ui/core";
import React, { useCallback, useState } from "react";
import pizza from "../assets/pizza.jpg";
import sushi from "../assets/sushi.jpeg";
import { useAppState } from "../hooks/useAppState";
import { PresentPage } from "./PresentPage";

export const Present3 = () => {
  const [state, setState] = useAppState();
  const [jidlo, setJidlo] = useState<string | null>(null);

  const setWin = useCallback(
    (what: string) => {
      setJidlo(what);
      setState((state) => ({
        ...state,
        presentDinnerFinished: true,
      }));
    },
    [setState]
  );

  if (state.presentDinnerFinished) {
    return (
      <PresentPage>
        <Flex p={4} pt={0} direction="column" align="center">
          <Heading textAlign="center" fontWeight="normal" mb={5}>
            Třetí dárek bude k jídlu{" "}
            <span role="img" aria-label="tasty">
              😋
            </span>
          </Heading>
          {jidlo ? (
            <Heading textAlign="center" fontWeight="normal">
              Ale {jidlo} nebude. Ani {jidlo === "sushi" ? "itálie" : "sushi"}.
            </Heading>
          ) : null}
          <Heading fontWeight="normal" mb={15}>
            Nech se překvapit{" "}
            <span role="img" aria-label="tasty">
              😛
            </span>
          </Heading>
          <Text>... v úterý 25.08.2020, 20:00</Text>
        </Flex>
      </PresentPage>
    );
  }

  return (
    <PresentPage>
      <Flex p={4} pt={0} direction="column" align="center">
        <Heading textAlign="center" fontWeight="normal" mb={5}>
          Třetí dárek bude k jídlu{" "}
          <span role="img" aria-label="tasty">
            😋
          </span>
        </Heading>
        <Heading fontSize={24} textAlign="center" mb={5}>
          Vyber, na co máš větší chuť
        </Heading>
        <Stack spacing={5} align="center">
          <Box w="80%" maxW={"500px"} shadow="lg">
            <Image
              key="sushi"
              rounded="lg"
              src={sushi}
              onClick={() => setWin("sushi")}
            />
          </Box>
          <Box w="80%" maxW={"500px"} shadow="lg">
            <Image
              key="pizza"
              rounded="lg"
              src={pizza}
              onClick={() => setWin("itálie")}
            />
          </Box>
        </Stack>
      </Flex>
    </PresentPage>
  );
};
